import type {App} from 'vue';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const store = createPinia();

//持久化插件文档 https://prazdevs.github.io/pinia-plugin-persistedstate/zh/guide/

export function setupStore(app: App<Element>) {
    store.use(piniaPluginPersistedstate);
    app.use(store);
}

export {store};
