import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import type {App} from "vue";
import {useUserStoreWithOut} from "@/store/user.ts";
import {Modal} from "ant-design-vue";
import {useDataStoreWithOut} from "@/store/data.ts";
import {pageViewApi} from "@/api";

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        component: () => import("@/layouts/layouts.vue"),
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'index',
                meta: {index: 1, keepAlive: true, title: '首页'},
                component: () => import("@/view/index/index.vue"),
            }, {
                path: '/love',
                name: 'love',
                meta: {index: 2, keepAlive: true, title: '猜你喜欢'},
                component: () => import("@/view/love/love.vue"),
            }, {
                path: '/actorDetail',
                name: 'actorDetail',
                meta: {index: 0, keepAlive: false, title: '演员'},
                component: () => import("@/view/actorDetail/actorDetail.vue"),
            }, {
                path: '/actor',
                name: 'actor',
                meta: {index: 0, keepAlive: true, title: '明星列表'},
                component: () => import("@/view/actor/actor.vue"),
            }, {
                path: '/labeLrank',
                name: 'labeLrank',
                meta: {index: 0, keepAlive: false, title: '排行榜'},
                component: () => import("@/view/labeLrank/labeLrank.vue"),
            }, {
                path: '/more',
                name: 'more',
                meta: {index: 0, keepAlive: false, title: '分类'},
                component: () => import("@/view/more/more.vue"),
            }, {
                path: '/search',
                name: 'search',
                meta: {index: 0, keepAlive: false, title: '搜索'},
                component: () => import("@/view/search/search.vue"),
            }, {
                path: '/login',
                name: 'login',
                meta: {index: 0, keepAlive: true, title: '登录'},
                component: () => import("@/view/login/login.vue"),
            }, {
                path: '/reg',
                name: 'reg',
                meta: {index: 0, keepAlive: true, title: '注册'},
                component: () => import("@/view/reg/reg.vue"),
            }, {
                path: '/question',
                name: 'question',
                meta: {index: 0, keepAlive: true, title: '常见问题'},
                component: () => import("@/view/question/question.vue"),
            }, {
                path: '/contact',
                name: 'contact',
                meta: {index: 0, keepAlive: true, title: '商务合作'},
                component: () => import("@/view/contact/contact.vue"),
            }, {
                path: '/newweb',
                name: 'newweb',
                meta: {index: 0, keepAlive: true, title: '备用网站'},
                component: () => import("@/view/newweb/newweb.vue"),
            }, {
                path: '/liveBroadcast',
                name: 'liveBroadcast',
                meta: {index: 3, keepAlive: true, title: '激情直播'},
                component: () => import("@/view/liveBroadcast/liveBroadcast.vue"),
            }, {
                path: '/specialSubject',
                name: 'specialSubject',
                meta: {index: 4, keepAlive: true, title: '专题'},
                component: () => import("@/view/specialSubject/specialSubject.vue"),
            }, {
                path: '/specialSubjectDetail',
                name: 'specialSubjectDetail',
                meta: {index: 0, keepAlive: false, title: '专题详情'},
                component: () => import("@/view/specialSubjectDetail/specialSubjectDetail.vue"),
            }, {
                path: '/vip',
                name: 'vip',
                meta: {index: 0, keepAlive: true, title: 'vip'},
                component: () => import("@/view/vip/vip.vue"),
            }, {
                path: '/play',
                name: 'play',
                meta: {index: 0, keepAlive: false, title: '播放页'},
                component: () => import("@/view/play/play.vue"),
            }, {
                path: '/user',
                name: 'user',
                meta: {index: 0, keepAlive: false, title: '个人中心'},
                component: () => import("@/view/user/user.vue"),
                redirect: '/user/modifyData',
                children: [
                    {
                        path: '/user/modifyData',
                        name: 'modifyData',
                        meta: {index: 0, keepAlive: true, title: '修改资料'},
                        component: () => import("@/view/user/view/modifyData.vue"),
                    }, {
                        path: '/user/voucherCenter',
                        name: 'voucherCenter',
                        meta: {index: 0, keepAlive: true, title: '充值中心'},
                        component: () => import("@/view/user/view/voucherCenter.vue"),
                    }, {
                        path: '/user/history',
                        name: 'history',
                        meta: {index: 0, keepAlive: true, title: '观影历史'},
                        component: () => import("@/view/user/view/history.vue"),
                    }, {
                        path: '/user/like',
                        name: 'like',
                        meta: {index: 0, keepAlive: true, title: '我的喜欢'},
                        component: () => import("@/view/user/view/like.vue"),
                    }, {
                        path: '/user/cardRecharge',
                        name: 'cardRecharge',
                        meta: {index: 0, keepAlive: true, title: '卡密充值'},
                        component: () => import("@/view/user/view/cardRecharge.vue"),
                    }, {
                        path: '/user/feedback',
                        name: 'feedback',
                        meta: {index: 0, keepAlive: true, title: '反馈中心'},
                        component: () => import("@/view/user/view/feedback.vue"),
                    }
                ]
            }
        ]
    },
];


// location / {
//     try_files $uri $uri/ /index.html;
// }
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // 滚动到顶部
        return {top: 0}
    }
});

router.beforeEach((to, _, next) => {
    const {setChannelCode, getChannelCode} = useDataStoreWithOut()
    const {getUser} = useUserStoreWithOut()

    if (to.query.channelCode) {
        setChannelCode(to.query.channelCode)
    }

    pageViewApi({
        page: to.meta.title,
        device: 'pc',
        channel: getChannelCode,
        is_vip: getUser.is_vip
    }).then(() => {
    })

    Modal.destroyAll();
    if (/user/.test(to.path)) {
        const {getToken} = useUserStoreWithOut()
        if (!getToken) {
            next({path: '/login'})
        }
    }
    next()
})

export function setupRouter(app: App<Element>) {
    app.use(router);
}

export {router};