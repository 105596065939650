//英文(en)
import en from './src/en.json'
//中文简体(zh-Hans)
import zhHans from './src/zh-Hans.json'
// 中文繁体(zh-Hant)
import zhHant from './src/zh-Hant.json'
import type {App} from "vue";
import {createI18n} from "vue-i18n";

const messages = {
    'zh-Hans': zhHans,
    'zh-Hant': zhHant,
    en,
}
const i18n = createI18n({
    locale: 'zh-Hans',// 获取已设置的语言
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    fallbackLocale: 'zh-Hans', // 当前语言无法找到匹配的翻译时，使用的备选语言
    messages
})

export function setupI18n(app: App<Element>) {
    app.use(i18n)
}

export const t = i18n.global.t