import './global.css'
import App from './App.vue'
import {setupStore} from "./store";
import {setupRouter} from "./router";
import {setupI18n} from "@/locale/locale.ts";
import {setupDirective} from "@/directives";
// import {useUserStoreWithOut} from "@/store/user.ts";
// import DisableDevtool from 'disable-devtool';

async function bootstrap() {
    const app = createApp(App)

    setupStore(app);
    setupRouter(app)
    setupI18n(app)
    setupDirective(app)

    // const {getUser} = useUserStoreWithOut()
    // if (process.env.NODE_ENV === 'production') {
    //     DisableDevtool({
    //         md5: '47ec2dd791e31e2ef2076caf64ed9b3d', //test123456
    //         url: 'http://www.baidu.com/s?wd=哥哥不能偷看哟???',
    //         tkName: 'du',
    //         ignore: () => {
    //             return getUser.user_id === 'test123456';
    //         }
    //     })
    // }
    app.mount('#app')
}

bootstrap()

