import type {App} from 'vue'
import VueLazyload from "vue-lazyload";
import err_img from "@/assets/images/err_img.jpg";

export function setupDirective(app: App<Element>) {
    app.use(VueLazyload, {
        preLoad: 1.3,
        error: err_img,
        loading: err_img,
        attempt: 1
    })
}
