import {get, post} from "@/utils/request.ts";

export function getAuth() {
    return post('/auth.html', {
        scurt: 'CZjbyBuvMhBOihqK1WNYjVppyr',
        acckey: 'bAgWcKJq5uJvCYvVeHX1acGfGbnd6cdvVVgOPyVjMBpMZQVOk7NMD7AVd2R6IIS2',
    })
}

export function getHome() {
    return get('/home.html')
}

export function getMovie(params: { m_id: string }) {
    return get('/movie.html', params)
}

export function getMovielist(params: any) {
    return get('/movieList.html', params)
}

export function setComment(params: any) {
    return post('/comment.html', params)
}

export function favoritesPostApi(params: any) {
    return post('/favorites.html', params)
}

export function getCommentApi(params: any) {
    return get('/comment.html', params)
}

export function registerApi(params: any) {
    return post('/user.html', params)
}

export function loginApi(params: any) {
    return post('/login.html', params)
}

export function accountApi(params: any) {
    return post('/account.html', params)
}

export function serialApi(params: any) {
    return post('/serial.html', params)
}

export function history_cleanApi(params?: any) {
    return get('/account/history_clean.html', params)
}

export function accountContactApi(params: any) {
    return post('/account/contact.html', params)
}

export function pageViewApi(params: any) {
    return post('/PageView.html', params)
}

export function accountChg_photoApi(params: any) {
    return post('/account/chg_photo.html ', params)
}

export function accountUpdate_userApi(params: any) {
    return post('/account/update_user.html', params)
}

export function accountExchange_vipApi(params: any) {
    return post('/account/exchange_vip.html ', params)
}

export function payApi(params: { pay_code: string, amount_id: string }) {
    return post('/pay.html ', params)
}

export function favoritesApi(params?: any) {
    return get('/favorites.html', params)
}

export function topicApi(params?: any) {
    return get('/topic.html', params)
}

export function historyApi(params?: any) {
    return get('/account/history.html', params)
}

export function actorApi(params?: any) {
    return get('/actor.html', params)
}

export function movieRelatedApi(params?: any) {
    return get('/MovieRelated.html', params)
}

export function searchApi(params?: any) {
    return get('/search.html', params)
}

export function configApi(params?: any) {
    return get('/config.html',params)
}

export function categoryApi(params?: any) {
    return get('/category.html', params)
}

export function tagsApi(params?: any) {
    return get('/tags.html', params)
}

export function accountprofileApi(params?: any) {
    return get('/account/profile', params)
}