import {store} from "@/store/index.ts";

interface UserState {
    user: any;
    token: string;
    globalModui: number | null;
    config: any
}

export const useUserStore = defineStore({
    id: "app-user",
    state: (): UserState => ({
        //用户数据
        user: {},
        // token
        token: '',
        config: {},
        globalModui: null
    }),
    getters: {
        getToken(): string {
            return this.token;
        },
        getUser(): any {
            return this.user;
        },
        getConfig(): any {
            return this.config;
        },
        getGlobalModui(): any {
            return this.globalModui;
        },
    },
    actions: {
        setToken(token: string) {
            this.token = token
        },
        setUser(data: any) {
            this.user = data;
        },
        setConfig(data: any) {
            this.config = data;
        },
        setGlobalModui(data: any) {
            this.globalModui = data;
        },
        quit() {
            this.user = {}
            this.token = ''
        }
    },
    persist: true,
});

export function useUserStoreWithOut() {
    return useUserStore(store);
}