<template>
  <ConfigProvider :locale="locale" :getPopupContainer="getPopupContainer" :theme="theme">
    <StyleProvider hash-priority="high" :transformers="[legacyLogicalPropertiesTransformer]">
      <router-view></router-view>
      <div class="fixed inset-0" style="z-index: 9999999;background: rgba(0, 0, 0, 0.5)" v-if="globalModui">
        <div class="w-full h-full flex items-center justify-center">
          <div class="w-[490px] h-[439px] rounded-md bg-white py-[30px] px-[20px] flex-col flex">
            <div class="dialog-top">
              <img src="@/assets/images/notice.png" class="notice-img">
              <div class="dialog-title-wrap">
                <span></span>
                <h3 class="dialog-title">瓜子社区重要公告！</h3>
                <span></span>
              </div>
            </div>
            <div class="flex-1">
              <p v-html="getConfig['popup_msg']"></p>
            </div>
            <button type="button" class="el-button" @click="globalModui=false">
              <span>确定</span>
            </button>
          </div>
        </div>
      </div>
    </StyleProvider>
  </ConfigProvider>
</template>

<script setup lang="ts">
import {ConfigProvider, legacyLogicalPropertiesTransformer, StyleProvider} from "ant-design-vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {configApi} from "@/api";
import {useUserStore} from "@/store/user.ts";
import {useDataStore} from "@/store/data.ts";

const globalModui = ref(false)
const useUser = useUserStore()
const {getConfig} = storeToRefs(useUser)


onMounted(() => {
  setTimeout(() => {
    if (useUser.globalModui) {
      if ((dayjs().valueOf() - useUser.globalModui) > 24 * 60 * 60 * 1000) {
        globalModui.value = true
        useUser.setGlobalModui(dayjs().valueOf())
      }
    } else {
      globalModui.value = true
      useUser.setGlobalModui(dayjs().valueOf())
    }
  }, 1000)
})


dayjs.locale('zh-cn');
onBeforeUnmount(() => {
  console.log('离开页面')
  // stopWatch()
})
const locale = ref(zhCN)

const theme = ref({
  token: {
    colorPrimary: '#FBB625',
    "wireframe": true //线框风格
  },
})

function getPopupContainer(node: any) {
  if (node) {
    return node.parentNode;
  }
  return document.body;
}


async function config() {
  const {setConfig} = useUserStore()
  const {getChannelCode} = useDataStore()
  try {
    const res = await configApi({channel: getChannelCode})
    setConfig(res.data)
  } catch (e) {
    console.log(e)
  }
}

config()

// const stopWatch = watch(router.currentRoute, () => {
//   config()
// }, {immediate: true, deep: true})

</script>

<style lang="less" scoped>
.dialog-top {
  border-radius: 10px;
  position: relative;
  padding-top: 65px;

  .notice-img {
    width: 108px;
    height: 108px;
    position: absolute;
    top: -63px;
    left: 170px;
  }

  .dialog-title-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      width: 60px;
      height: 1px;
      background: #fff6e0;
    }

    .dialog-title {
      margin: 0 16px;
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #f9ac04;
    }
  }
}

p {
  margin: 25px 0 30px;
  line-height: 24px;
}

.el-button {
  width: 260px;
  height: 50px;
  cursor: pointer;
  padding: 0;
  border-radius: 24px;
  background: linear-gradient(90deg, #ffc055 40%, #ffedc4);
  border: none;
  color: #62372f;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  transition: .1s;
  margin: 0 auto;
  display: block;
}
</style>
